"use strict";

class RecieveDataChannel extends RTC {
    constructor(onMessage = () => null, onIce = () => null, onOpen = () => null) {
        super(onMessage, onIce, onOpen);
    }

    connect(iceServers) {
        this._connect(iceServers);
        this.peerConnection.ondatachannel = this._onDataChannel.bind(this);
    }

    _onDataChannel(event) {
        console.log("Building new data channel");
        if (this.dataChannel) {
            console.warn("There is an existing data channel, closing new one")
            event.channel.close();
            return;
        }

        this.dataChannel = event.channel;
        this.dataChannel.binaryType = 'arraybuffer';
        this.dataChannel.onmessage = this._onRecieveMessage.bind(this);
        this.dataChannel.onopen = () => {
            console.log("Data channel open as reciever");
            sendEvent("dataChannelOpened", { "orderedDataChannel": this.dataChannel.ordered, "sender": false });
            if (this.dataChannel.ordered == false) console.warn("Unreliable data channel!");
            this.onOpen();
        }
        
        this.dataChannel.onclose = () => this.closeConnections.bind(this);
        this.dataChannel.onerror = (err) => { throw err };
        console.log("New data channel built");
    }
}